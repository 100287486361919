import React from 'react';
import ContentPageLayout from "../components/ContentPageLayout";
import Seo from "../components/seo";
import firebase from "firebase";
import { navigate } from "gatsby";

const RecoverPassword = ({ location }) => {

    let auth;
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const actionCode = queryParams.get('oobCode');
    const continueUrl = queryParams.get('continueUrl');
    const lang = queryParams.get('lang') || 'en';
    const config = {
        'apiKey': "AIzaSyDHVNMn1Cb4DbVP18Ws3nABVTqVZYyj_ls"
    };

    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

    React.useEffect(() => {
        // const app =  initializeApp(config);
        // auth = getAuth(app);
        load();
    }, []);

    const load = async () => {
        try {
            // let fire = firebase.initializeApp(config);
            let res = await firebase.auth().verifyPasswordResetCode(actionCode);
            console.log(res);
        } catch (error) {
            console.log(error);
            alert(error.message);
            navigate('/');
        }
    }

    const handleSubmit = async () => {

        const acentos = /[áéíóúÁÉÍÓÚ]/;
        const charEspecial = /[@$?¡\-_]/;
        const upperCaseRegex = /[A-Z]/;
        const alphanumericRegex = /[0-9]/;

        if (!newPassword) {
            alert("Debes ingresar una contraseña")
            return false
        }

        if (!upperCaseRegex.test(newPassword)) {
            alert("La contraseña debe de tener al menos una letra en mayúscula")
            return false
        }

        if (!charEspecial.test(newPassword)) {
            alert("La contraseña debe de contener al menos un caracter especial: @ $ ? ¡ \ - _")
            return false
        }

        if (acentos.test(newPassword)) {
            alert("Los acentos no están permitidos")
            return false
        }

        if (!alphanumericRegex.test(newPassword)) {
            alert("La contraseña debe contener letras y números.")
            return false
        }

        if (newPassword.length < 8) {
            alert("La contraseña debe ser mayor a 8 caracteres")
            return false
        }

        if (newPassword !== newPasswordConfirm) {
            alert("Las contraseñas no son iguales")
            return false
        }

        try {
            await firebase.auth().confirmPasswordReset(actionCode, newPassword);
            alert('Tu contraseña se ha cambiado con éxito.');
            navigate('/');
        } catch (error) {
            console.log(error);
            alert(error.message);
            navigate('/');
        }
    }

    return (
        <ContentPageLayout>
            <Seo title="Aviso de Privacidad" />
            <div className='boots'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-4'>
                            <h1 className='mt-4'>Cambiar contraseña</h1>
                            <p>La contraseña debe ser diferente a la anterior, esta debe contener una letra en mayúscula y al menos 8 caracteres alfanuméricos.</p>
                            <form>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Ingresa tu nueva contraseña</label>
                                    <input type="password" class="form-input" onChange={(e) => setNewPassword(e.target.value)} />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Confirma tu nueva contraseña</label>
                                    <input type="password" class="form-input" onChange={(e) => setNewPasswordConfirm(e.target.value)} />
                                </div>
                                <button type="button" class="bg-sc-primary rounded-full shadow-2xl p-2 text-sc-titles  cursor-pointer" onClick={handleSubmit}>Continuar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </ContentPageLayout>
    )
}

export default RecoverPassword;
